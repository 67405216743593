// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-art-fairs-biaf-js": () => import("./../../../src/pages/about/art-fairs/biaf.js" /* webpackChunkName: "component---src-pages-about-art-fairs-biaf-js" */),
  "component---src-pages-about-art-fairs-biennale-paris-js": () => import("./../../../src/pages/about/art-fairs/biennale-paris.js" /* webpackChunkName: "component---src-pages-about-art-fairs-biennale-paris-js" */),
  "component---src-pages-about-art-fairs-brafa-js": () => import("./../../../src/pages/about/art-fairs/brafa.js" /* webpackChunkName: "component---src-pages-about-art-fairs-brafa-js" */),
  "component---src-pages-about-art-fairs-js": () => import("./../../../src/pages/about/art-fairs.js" /* webpackChunkName: "component---src-pages-about-art-fairs-js" */),
  "component---src-pages-about-art-fairs-masterpiece-london-js": () => import("./../../../src/pages/about/art-fairs/masterpiece-london.js" /* webpackChunkName: "component---src-pages-about-art-fairs-masterpiece-london-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-news-js": () => import("./../../../src/pages/about/news.js" /* webpackChunkName: "component---src-pages-about-news-js" */),
  "component---src-pages-about-our-history-js": () => import("./../../../src/pages/about/our-history.js" /* webpackChunkName: "component---src-pages-about-our-history-js" */),
  "component---src-pages-about-press-js": () => import("./../../../src/pages/about/press.js" /* webpackChunkName: "component---src-pages-about-press-js" */),
  "component---src-pages-about-services-js": () => import("./../../../src/pages/about/services.js" /* webpackChunkName: "component---src-pages-about-services-js" */),
  "component---src-pages-about-the-london-gallery-js": () => import("./../../../src/pages/about/the-london-gallery.js" /* webpackChunkName: "component---src-pages-about-the-london-gallery-js" */),
  "component---src-pages-about-the-milan-galleries-js": () => import("./../../../src/pages/about/the-milan-galleries.js" /* webpackChunkName: "component---src-pages-about-the-milan-galleries-js" */),
  "component---src-pages-collection-accessories-js": () => import("./../../../src/pages/collection/accessories.js" /* webpackChunkName: "component---src-pages-collection-accessories-js" */),
  "component---src-pages-collection-beds-js": () => import("./../../../src/pages/collection/beds.js" /* webpackChunkName: "component---src-pages-collection-beds-js" */),
  "component---src-pages-collection-bookcases-cabinets-js": () => import("./../../../src/pages/collection/bookcases-&-cabinets.js" /* webpackChunkName: "component---src-pages-collection-bookcases-cabinets-js" */),
  "component---src-pages-collection-fine-art-sculptures-js": () => import("./../../../src/pages/collection/fine-art-&-sculptures.js" /* webpackChunkName: "component---src-pages-collection-fine-art-sculptures-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-collection-lighting-js": () => import("./../../../src/pages/collection/lighting.js" /* webpackChunkName: "component---src-pages-collection-lighting-js" */),
  "component---src-pages-collection-rugs-js": () => import("./../../../src/pages/collection/rugs.js" /* webpackChunkName: "component---src-pages-collection-rugs-js" */),
  "component---src-pages-collection-seating-js": () => import("./../../../src/pages/collection/seating.js" /* webpackChunkName: "component---src-pages-collection-seating-js" */),
  "component---src-pages-collection-tables-js": () => import("./../../../src/pages/collection/tables.js" /* webpackChunkName: "component---src-pages-collection-tables-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-article-templates-js": () => import("./../../../src/templates/article-templates.js" /* webpackChunkName: "component---src-templates-article-templates-js" */),
  "component---src-templates-item-templates-js": () => import("./../../../src/templates/item-templates.js" /* webpackChunkName: "component---src-templates-item-templates-js" */)
}

